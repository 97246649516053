import {Routes, RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {UmbracoConfigGuard} from 'app/auth/umbraco-config-guard';
import {MaintanceErrorComponent} from 'app/containers/error-page/maintance-error/maintance-error';
import {MaintenanceGuard} from './auth/maintenance.guard';

const appRoutes: Routes = [
    {path: '', redirectTo: 'app', pathMatch: 'full'},
    {path: 'app', loadChildren:
            () => import('./containers/layout/layout.module').then(m => m.LayoutModule), canActivate: [UmbracoConfigGuard]},
    {path: 'maintance-error', component: MaintanceErrorComponent, canActivate: [MaintenanceGuard]},
    {path: '**', redirectTo: 'app'}
];

@NgModule({
    imports: [RouterModule.forRoot(appRoutes, {useHash: false})],
    exports: [RouterModule]
})

export class AppRoutingModule {
}
