<navbar [hideLangSwitcher]="true"></navbar>
<div class="dashboard-wrap">
    <div class="col-lg-12 col-md-12 content-holder" *ngIf="envType === 'zim' && !isMsalError">
        <div class="monitor-intro">
            <div class="row">
                <div class="col-sm-12" *ngIf="isDefaultLanguage">
                    <div class="notice white-box">
                        <h3>Dashboard</h3>
                        <p>Welcome to your personal area</p>
                    </div>
                </div>
                <div class="col-sm-12" *ngIf="!isDefaultLanguage">
                    <div class="notice white-box">
                        <h3 [innerHTML]="'DASHBOARD.ImportantNotice' | translate"></h3>
                        <p [innerHTML]="'DASHBOARD.ImportantNotice.Content' | translate"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="error-page" class="maintance col-sm-12">
        <div class="mask-copy">
            <div class="hi-there">
                <ng-container *ngIf="msg?.text && !isMsalError">{{msg?.text}}</ng-container>
                <ng-container *ngIf="isDefaultLanguage && !msg?.text" && !isMsalError>Service currently unavailable. Please try again later.</ng-container>
                <ng-container *ngIf="!isDefaultLanguage && !msg?.text && msg?.translationKey && !isMsalError">{{msg?.translationKey | translate: msg?.params}}</ng-container>
                <div *ngIf="isMsalError">Sorry, you have log in issue.</div>
                <div *ngIf="isMsalError">Please turn off a blocking of third party cookies in your browser settings.</div>
            </div>
            <div class="try-again"  *ngIf="isTryAgain">
                <input class="btn btn-default" type="button" [value]="'OK'" (click)="tryAgain()">
            </div>
        </div>
    </div>
</div>
