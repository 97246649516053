import {Component, OnInit, OnDestroy} from '@angular/core';
import {CookieService} from 'ngx-cookie';
import {ConfigService} from 'app/config/config.sevice';
import {AuthService} from 'app/auth/auth.service';
import {RouterWrapper} from 'app/core/routing/router.wrapper';
import {TranslateService} from '@ngx-translate/core';
import {LoaderService} from '../../../shared/services/loader.service';
import {ActivatedRoute} from '@angular/router';
import {HttpErrorResponse} from '@angular/common/http';
import {RoutePermissionService} from '../../../core/permission/route-permissions.service';
import {EnvironmentConfiguration} from '../../../config/evironment-config';
import {BroadcastService, MsalService} from '@azure/msal-angular';
import {Subscription} from 'rxjs';

@Component({
    selector: 'maintance-error',
    templateUrl: './maintance-error.html',
    styleUrls: ['./maintance-error.scss']
})
export class MaintanceErrorComponent implements OnInit, OnDestroy {

    isDefaultLanguage = false;
    msg: {translationKey: string, text: string, params: any};
    isTryAgain = false;
    envType = 'zim';
    broadcastSubscription: Subscription;
    msalCookieErrorText = '';
    isMsalError = false;

    constructor(private configService: ConfigService,
                private cookiesService: CookieService,
                private translateService: TranslateService,
                private authService: AuthService,
                private router: RouterWrapper,
                private loaderService: LoaderService,
                private route: ActivatedRoute,
                private msalService: MsalService,
                private broadcastService: BroadcastService,
                private environmentConfig: EnvironmentConfiguration) {
        this.envType = this.environmentConfig.get('type').toLowerCase();

        this.broadcastSubscription = this.broadcastService.subscribe('msal:acquireTokenFailure', payload => {
            if (payload.errorMessage.includes('AADB2C90077')) {
                if (navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
                    navigator.userAgent &&
                    navigator.userAgent.indexOf('CriOS') === -1 &&
                    navigator.userAgent.indexOf('FxiOS') === -1) {
                        this.isMsalError = true;
                } else {
                    this.msalService.logout();
                }
            }
        });
    }

    ngOnInit(): void {
        this.route.queryParamMap.subscribe(queryParams => {
            const lang = this.cookiesService.get('language') || 'en';
            this.msg = JSON.parse(queryParams.get('msg'));
            this.isTryAgain = queryParams.get('isTryAgain') === 'true';
            if ( this.authService.isAuthentificated()) {
                this.translateService.use(lang).subscribe(
                    (data) => {
                        this.isDefaultLanguage = data instanceof HttpErrorResponse;
                        this.loaderService.hideCoverAllSpinner();
                    },
                    () => {
                        this.isDefaultLanguage = true;
                        this.loaderService.hideCoverAllSpinner();
                    }
                );
            } else {
                setTimeout(() => this.loaderService.hideCoverAllSpinner(), 1000 );
                this.isDefaultLanguage = true;
            }
        })
    }

    ngOnDestroy() {
        this.broadcastSubscription.unsubscribe();
    }

    tryAgain() {
        this.router.toLogout();
    }
}
