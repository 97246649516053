import {Component, OnInit, Inject} from '@angular/core';
import {Router, NavigationStart, NavigationEnd, ActivatedRoute} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {DOCUMENT} from '@angular/common';

import {EnvironmentConfiguration} from 'app/config/evironment-config';
import {ConfigurePiwikTracker, UsePiwikTracker} from 'Angular2Piwik';
import {TranslateService} from '@ngx-translate/core';
import {AccessibilityService} from './shared/services/accessibility.service';
import {LoaderService} from './shared/services/loader.service';
import {StorageDataReader} from './shared/services/storage-data-reader.service';
import {SharedDataService} from './shared/services/shared-data.service';
import {AuthService} from './auth/auth.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
    skipMainLink: string;
    isAccessibilityMode = false;
    loader: boolean = false;
    coverAllLoader: boolean = true;
    envType = 'zim';

    constructor(private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title,
        private environmentConfig: EnvironmentConfiguration,
        private configurePiwikTracker: ConfigurePiwikTracker,
        private usePiwikTracker: UsePiwikTracker,
        private translateService: TranslateService,
        private accessibilityService: AccessibilityService,
        private loaderService: LoaderService,
        private localStor: StorageDataReader,
        private shareDataService: SharedDataService,
        private authService: AuthService,
        @Inject(DOCUMENT) private _document: HTMLDocument) {
        this.envType = this.environmentConfig.get('type').toLowerCase();
        this._document.getElementById('appFavicon').setAttribute('href', `/assets/icon/favicon${this.envType.toUpperCase()}.ico`);
        this.loaderService.loaderStateChanged.subscribe(state => {
            this.loader = state;
        });

        this.loaderService.coverAllLoaderStateChanged.subscribe(state => {
            this.coverAllLoader = state;
        });
    }

    ngOnInit() {
        this.authService.authInit();
        this.removeOldColumns();
        let thisUrl = '';
        this.router.events.subscribe(event => {

            if (event instanceof NavigationStart) {
                thisUrl = event.url;
            }

            if (event instanceof NavigationEnd) {
                this.skipMainLink = window.location.href.includes('#') ? window.location.href : `${window.location.href}#main`;
            }
        });

        this.router.events
            .filter(event => event instanceof NavigationEnd)
            .map(() => this.activatedRoute)
            .map(route => {
                while (route.firstChild) {
                    route = route.firstChild;
                }
                return route;
            })
            .filter(route => route.outlet === 'primary')
            .mergeMap(route => route.data)
            .subscribe((event) => this.afterRoute(event, thisUrl));

        this.translateService.onLangChange.subscribe(event => {
            this.accessibilityService.updateLangOnHtml(event.lang);
        });
    }

    private afterRoute(event: any, url: string) {
        if (event['title']) {
            this.titleService.setTitle(this.translateService.instant(event['title']));
            this.shareDataService.setHeaderTitle(event['title'].replace('.PageTitle', '.HeaderTitle'));
            this.translateService.onLangChange.subscribe(() => {
                this.titleService.setTitle(this.translateService.instant(event['title']));
            })
        }
        this.configurePiwikTracker.setDocumentTitle();
        this.configurePiwikTracker.setCustomUrl(url);
        this.configurePiwikTracker.setReferrerUrl(window.location.href);
        // this.configurePiwikTracker.setCustomVariable(1, 'userId', 'idValue', 'visit');
        const userData = this.localStor.getZimAuthData();
        if (userData) {
            this.configurePiwikTracker.setUserId(userData.contact.userName);
        }
        this.usePiwikTracker.trackPageView();
    }

    removeOldColumns(): void {

        // we should remove old aliases

        localStorage.removeItem('copyBl_table');
        localStorage.removeItem('bookingConfirmation_table');
        localStorage.removeItem('myShipmentsExport_table');
        localStorage.removeItem('seawayBill_table');
        localStorage.removeItem('copyInvoice_table');
        localStorage.removeItem('arrivalNotice_table');
        localStorage.removeItem('deliveryOrder_table');
        localStorage.removeItem('myShipmentsImport_table');
        localStorage.removeItem('monitor_table');
        localStorage.removeItem('print_table');
        const sort = localStorage.getItem('copyInvoice_sort:');
        if (sort === 'INVOICE_DATE') {
            localStorage.removeItem('copyInvoice_sort:');
        }
    }

    initBlur(event) {
        if (event.target.localName === 'input'
            || event.target.localName === 'select'
            || event.target.className === 'sideBarActiveLink'
            || event.target.localName === 'textarea') {
            return;
        } else {
            event.target.blur();
        }
    }

    switchAccessibilityMode() {
        this.isAccessibilityMode = !this.isAccessibilityMode;

        if (this.isAccessibilityMode) {
            this.accessibilityService.enableAccessibilityMode();
        } else {
            this.accessibilityService.disableAccessibilityMode();
        }

        return false;
    }
}

declare global {
    interface Date {
        toFilterString(): string;
    }
}

Date.prototype.toFilterString = function () {
    return `${this.getFullYear()}-${('0' + (this.getMonth() + 1)).slice(-2)}-${('0' + this.getDate()).slice(-2)}`
};
