import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
const envConf = window['caEnvironmentConfig'];

if (environment.production) {
  enableProdMode();
}

// Handle redirection errors from AzureAD
if (window.location.href.includes('error_description=AADB2C90118')) {
    window.location.href = envConf.signIn.forgotPasswordLink;
} else if (window.location.href.includes('error_description=AADB2C90091') ||
    (!window.location.href.includes('state=') && window.location.href.includes('id_token='))) {
    window.location.href = window.location.origin;
} else {
    platformBrowserDynamic().bootstrapModule(AppModule);
}

// platformBrowserDynamic().bootstrapModule(AppModule);

// export function main(): Promise<any> {
//   return platformBrowserDynamic()
//     .bootstrapModule(AppModule)
//     .then(decorateModuleRef)
//     .catch(err => console.error(err));
// }

// // needed for hmr
// // in prod this is replace for document ready
// bootloader(main);
