import { registerLocaleData } from '@angular/common';
import localeHe from '@angular/common/locales/he';
import localeKo from '@angular/common/locales/ko';
import localeZhHans from '@angular/common/locales/zh-Hans';
import localeZhHant from '@angular/common/locales/zh-Hant';
import { MSALConfigFactory, MSALAngularConfigFactory } from 'config/msal-config';
import { MsalService, MsalInterceptor, BroadcastService } from '@azure/msal-angular';
import { MobileService } from './shared/services/mobile.service';
import { AuthInterceptor } from './auth/auth.interceptor';
registerLocaleData(localeHe);
registerLocaleData(localeKo);
registerLocaleData(localeZhHans);
registerLocaleData(localeZhHant);
const authInterceptorFactory = (auth, broadcastService, mobileService) => {
    return (mobileService.isApple() || mobileService.isIE()) ? new AuthInterceptor() : new MsalInterceptor(auth, broadcastService);
};
const ɵ0 = authInterceptorFactory;
const ɵ1 = MSALConfigFactory, ɵ2 = MSALAngularConfigFactory;
export class AppModule {
}
export { ɵ0, ɵ1, ɵ2 };
