import {PostfixPipe} from './pipes/add-postfix';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedDataService} from 'app/shared/services/shared-data.service';
import {TableLoaderComponent} from 'app/shared/components/table-loader/table-loader.component';
import {MessageModalService} from 'app/shared/components/message-modal/message-modal.service';
import {MultiselectDropdownModule} from 'angular-2-dropdown-multiselect';
import {Select2Module} from 'ng2-select2/ng2-select2';
import {ShowInvalidSearchModule} from 'app/shared/directives/show-invalid-search/show-invalid-search.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {InvalidSearchComponent} from 'app/shared/components/invalid-search/invalid-search.component';
import {TranslateModule} from '@ngx-translate/core';
import {DatepickerWrapModule} from 'app/shared/directives/datepicker-wrap/datepicker-wrap.module';
import {StatusWrapModule} from 'app/shared/directives/status-wraper/status-wraper.module';
import {NavbarComponent} from 'app/containers/layout/navbar/navbar.component';
import {NavBarOpenModule} from 'app/shared/directives/navbar-open/navbar-open.module';
import {CheckInvalidDate} from 'app/shared/pipes/check-invalid-date.pipe';
import {MomentTimezone} from 'app/shared/pipes/utc-pipe';
import {BracketsPostfixPipe} from 'app/shared/pipes/brackets-postfix';
import {DashfixPipe} from 'app/shared/pipes/add-dash';
import {PlaceholderTranslaterModule} from 'app/shared/directives/select2-placeholder-translater/placeholder-translater.module';
import {ShowModule} from './directives/show/show.module';
import {MomentModule} from 'angular2-moment';
import {AutocompleteSearchComponent} from 'app/shared/components/autocomplete-search/autocomplete-search.component';
import {ScrollTableMobileModule} from './directives/scroll-table-mobile/scroll-table-mobile.module';
import {DashSpacesPipe} from './pipes/add-dash-spaces';
import {GdprPopupComponent} from './components/gdpr-popup/gdpr-popup.component';
import {GdprPopupService} from './components/gdpr-popup/gdpr-popup.service';
import {SafeSanitizePipe} from './pipes/safe-sanitize.pipe';
import {StylesheetSwitcherService} from './services/stylesheet-switcher.service';
import {PrintPickerComponent} from './components/print-picker/print-picker.component';
import {MobileNumberComponent} from './components/mobile-number/mobile-number.component';
import {MobileNumberService} from './components/mobile-number/mobile-number.service';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {WrapDatepickerComponent} from './components/wrap-datepicker/wrap-datepicker.component';
import {FollowOrderPipe} from './pipes/follow-order.pipe';
import {SearchPanelComponent} from './components/search-panel/search-panel.component';
import {SearchPanelConfigService} from './components/search-panel/search-panel.config';
import { MobileMissedAlertComponent } from './components/mobile-missed-alert/mobile-missed-alert.component';
import {PagingInfoModule} from './directives/paging-info/paging-info.module';
import {Select2IdModule} from './directives/select2-id/select2-id.module';
import {NgxPaginationModule} from 'ngx-pagination';
import {PagingShowModule} from './directives/paging-show/paging-show.module';
import {ResultTableComponent} from './components/result-table/result-table.component';
import {TableBodyComponent} from './components/table-body/table-body.component';
import {ClickOutsideModule} from './directives/click-outside/click-outside.module';
import {MobileResultTableComponent} from './components/mobile-result-table/mobile-result-table.component';
import {MobileTableHeaderComponent} from './components/mobile-result-table/mobile-table-header/mobile-table-header.component';
import {MobileTableBodyComponent} from './components/mobile-result-table/mobile-table-body/mobile-table-body.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { SortingPanelComponent } from './components/sorting-panel/sorting-panel.component';
import {BannersComponent} from './banners/banners.component';
import {ContainerDetailsComponent} from './components/container-details/container-details.component';
import {TagInputModule} from 'ngx-chips';
import {NgxSmartModalModule, NgxSmartModalService} from 'ngx-smart-modal';

@NgModule({
    imports: [CommonModule, FormsModule, MultiselectDropdownModule, Select2Module, ClickOutsideModule,
        ShowInvalidSearchModule, TranslateModule, DatepickerWrapModule, StatusWrapModule,
        NavBarOpenModule, PlaceholderTranslaterModule, ShowModule, ScrollTableMobileModule,
        ReactiveFormsModule, NgxSmartModalModule.forRoot(), NgbModule, PagingInfoModule, Select2IdModule,
        PagingShowModule, NgxPaginationModule, MomentModule, InfiniteScrollModule, TagInputModule],
    exports: [CommonModule, FormsModule, PostfixPipe, BracketsPostfixPipe, MobileMissedAlertComponent,
        DashfixPipe, MomentTimezone, TableLoaderComponent, MultiselectDropdownModule, Select2Module,
        ShowInvalidSearchModule, InvalidSearchComponent, NavbarComponent, DatepickerWrapModule, ClickOutsideModule,
        StatusWrapModule, NavBarOpenModule, CheckInvalidDate, PlaceholderTranslaterModule, ShowModule,
        MomentModule, AutocompleteSearchComponent, ScrollTableMobileModule, DashSpacesPipe, NgxSmartModalModule,
        GdprPopupComponent, SafeSanitizePipe, PrintPickerComponent, MobileNumberComponent, WrapDatepickerComponent, FollowOrderPipe,
        SearchPanelComponent, ResultTableComponent, TableBodyComponent, Select2IdModule, BannersComponent, MobileResultTableComponent,
        MobileTableHeaderComponent, MobileTableBodyComponent, SortingPanelComponent, ContainerDetailsComponent, NgbModule, TagInputModule],
    declarations: [PostfixPipe, BracketsPostfixPipe, DashfixPipe, MomentTimezone, AutocompleteSearchComponent, SafeSanitizePipe,
        TableLoaderComponent, NavbarComponent, InvalidSearchComponent, CheckInvalidDate,
        DashSpacesPipe, GdprPopupComponent, MobileMissedAlertComponent,
        PrintPickerComponent, MobileNumberComponent, WrapDatepickerComponent,
        ResultTableComponent, FollowOrderPipe, SearchPanelComponent,
        TableBodyComponent, BannersComponent, MobileResultTableComponent, MobileTableHeaderComponent, MobileTableBodyComponent,
        SortingPanelComponent, ContainerDetailsComponent],
    providers: [ GdprPopupService, StylesheetSwitcherService,
        MobileNumberService, SearchPanelConfigService, DashfixPipe, CheckInvalidDate,
        BracketsPostfixPipe, PostfixPipe, NgxSmartModalService]
})

export class SharedModule {
    static forRoot() {
        return {
            ngModule: SharedModule,
            providers: [SharedDataService, MessageModalService]
        };
    }
}
