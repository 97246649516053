import { HttpClientService } from '../../core/http/http-client.service';
import { EnvironmentConfiguration } from '../../config/evironment-config';
import { Subject } from 'rxjs';
import * as i0 from "@angular/core";
import * as i1 from "../../core/http/http-client.service";
import * as i2 from "../../config/evironment-config";
export class NotificationsService {
    constructor(httpClient, environmentConfiguration) {
        this.httpClient = httpClient;
        this.environmentConfiguration = environmentConfiguration;
        this.resetState = new Subject();
        this.baseApi = environmentConfiguration.get('baseApiUrl');
        this.notificationsEndPoint = environmentConfiguration.get('notificationsEndPoint');
    }
    getNewNotificationsCounter(dsaId) {
        return this.httpClient.get(this.baseApi + this.notificationsEndPoint.getNewNotificationsCounterConsumer + `?dsaID=${dsaId}`);
    }
    getNotificationEvents(data) {
        return this.httpClient.post(this.baseApi + this.notificationsEndPoint.getNotificationEventsConsumer, data);
    }
    resetNotificationsCounter(data) {
        return this.httpClient.post(this.baseApi + this.notificationsEndPoint.resetNotificationsCounterConsumer, data);
    }
    getDailyReports(data) {
        return this.httpClient.post(this.baseApi + this.notificationsEndPoint.getDailyReportsConsumer, data);
    }
    getDailyReportDocument(data) {
        return this.httpClient.post(this.baseApi + this.notificationsEndPoint.getDailyReportDocumentConsumer, data);
    }
    getVesselEventBLs(data) {
        return this.httpClient.post(this.baseApi + this.notificationsEndPoint.getVesselEventBLsConsumer, data);
    }
    setResetState(state) {
        this.resetState.next(state);
    }
    getResetState() {
        return this.resetState.asObservable();
    }
}
NotificationsService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function NotificationsService_Factory() { return new NotificationsService(i0.ɵɵinject(i1.HttpClientService), i0.ɵɵinject(i2.EnvironmentConfiguration)); }, token: NotificationsService, providedIn: "root" });
