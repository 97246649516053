import {AppComponent} from './app.component';
import {AppRoutingModule} from 'app/app-routing.module';
import {CoreModule} from 'app/core/core.module';
import {ErrorModule} from 'app/containers/error-page/error-page.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import localeHe from '@angular/common/locales/he';
import localeKo from '@angular/common/locales/ko';
import localeZhHans from '@angular/common/locales/zh-Hans';
import localeZhHant from '@angular/common/locales/zh-Hant';
import {MSALConfigFactory, MSALAngularConfigFactory} from 'config/msal-config';
import {MsalModule, MSAL_CONFIG, MSAL_CONFIG_ANGULAR, MsalService, MsalInterceptor, BroadcastService} from '@azure/msal-angular';
import {MsalComponent} from './auth/msal.component';
import {MobileService} from './shared/services/mobile.service';
import {AuthInterceptor} from './auth/auth.interceptor';
import {CustomMsalGuard} from './auth/custom-msal.guard';
import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Angular2PiwikModule} from 'Angular2Piwik';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {ToastrModule} from 'ngx-toastr';
import {NgModule} from '@angular/core';
registerLocaleData(localeHe);
registerLocaleData(localeKo);
registerLocaleData(localeZhHans);
registerLocaleData(localeZhHant);

const authInterceptorFactory = (auth: MsalService ,  broadcastService: BroadcastService, mobileService: MobileService) => {
    return (mobileService.isApple() || mobileService.isIE()) ? new AuthInterceptor() : new MsalInterceptor(auth, broadcastService);
};

@NgModule({
    declarations: [
        AppComponent,
        MsalComponent
    ],
    imports: [
        CoreModule,
        BrowserModule,
        FormsModule,
        AppRoutingModule,
        ReactiveFormsModule,
        Angular2PiwikModule,
        ErrorModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        ToastrModule.forRoot({
            timeOut: 3000,
            maxOpened: 2,
            autoDismiss: true,
            preventDuplicates: true
        }),
        MsalModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useFactory: authInterceptorFactory,
            deps: [MsalService, BroadcastService, MobileService],
            multi: true
        },
        {
            provide: MSAL_CONFIG,
            useFactory: MSALConfigFactory
        },
        {
            provide: MSAL_CONFIG_ANGULAR,
            useFactory: MSALAngularConfigFactory
        },
        MsalService,
        CustomMsalGuard
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
