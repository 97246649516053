import {NgModule, APP_INITIALIZER} from '@angular/core';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {AuthService} from 'app/auth/auth.service';
import {HttpClientService} from 'app/core/http/http-client.service';
import {RouterWrapper} from 'app/core/routing/router.wrapper';
import {PermissionsGuard} from 'app/core/permission/customer-permissions-guard';
import {RoutePermissionService} from 'app/core/permission/route-permissions.service';

import {PermissionsMap} from 'app/core/permission/app.permissions-map.config';
import {MessageModalService} from 'app/shared/components/message-modal/message-modal.service';
import {MobileService} from 'app/shared/services/mobile.service';
import {LoaderService} from 'app/shared/services/loader.service';
import {ConfigService} from 'app/config/config.sevice';
import {LocalizationService} from 'app/shared/services/localization.service';
import {PdfPopupManager} from 'app/shared/components/pdf-popup/pdf-popup.manager';
import {ViewSettings} from 'app/core/views/view-settings';
import {UmbracoConfigGuard} from 'app/auth/umbraco-config-guard';
import {EnvironmentConfiguration} from 'app/config/evironment-config';

import {ConfigurePiwikTracker, UsePiwikTracker} from 'Angular2Piwik';
import {CommonModule} from '@angular/common';
import {StorageDataReader} from 'app/shared/services/storage-data-reader.service';
import {Select2Component} from 'ng2-select2';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {CookieModule} from 'ngx-cookie';
import {SharedDataService} from 'app/shared/services/shared-data.service';
import {RequestHelper} from 'app/core/http/request-helper';
import {DateRangeService} from 'app/shared/services/date-range.service';
import {ScreenGeneral} from 'app/shared/utils/screen-general';
import {ContainersTracingService} from 'app/shared/services/containers-tracing.service';
import {ScrollingService} from 'app/shared/services/scrolling.service';
import {Global} from 'app/shared/global';
import {AccessibilityService} from '../shared/services/accessibility.service';
import {MySettingsShareDataService} from '../shared/services/my-settings-share-data.service';
import {FileService} from '../shared/services/file.service';
import {ErrorHandlerInterceptor} from './interceptors/error-handler.interceptor';
import {MaintenanceGuard} from 'app/auth/maintenance.guard';

export function environmentInit(config: EnvironmentConfiguration) {
    return () => config.loadConfig();
}

/*----prototype----*/

// Select2Component.prototype.ngAfterViewInit = function () {
//     const that = this;
//     this.element = jQuery(this.selector.nativeElement);
//     this.initPlugin();
//     if (typeof this.value !== 'undefined') {
//         this.setElementValue(this.value);
//     }
//     this.element.on('select2:select', function () {
//         that.valueChanged.emit({
//             value: that.element.val()
//         });
//     });
//
//     this.element.on('select2:unselect', function () {
//         that.valueChanged.emit({
//             value: ''
//         });
//     });
//
// };


@NgModule({
    exports: [TranslateModule],
    imports: [
        CommonModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useClass: LocalizationService,
                deps: [HttpClientService, EnvironmentConfiguration, RouterWrapper]
            }
        }),
        CookieModule.forRoot()],
    declarations: [],
    providers: [
        SharedDataService,
        AuthService,
        LocalizationService,
        MobileService,
        PdfPopupManager,
        MessageModalService,
        LoaderService,
        RoutePermissionService,
        UmbracoConfigGuard,
        MaintenanceGuard,
        PermissionsGuard,
        PermissionsMap,
        HttpClientService,
        RequestHelper,
        RouterWrapper,
        ConfigService,
        LocalizationService,
        StorageDataReader,
        ViewSettings,
        EnvironmentConfiguration,
        Global,
        ConfigurePiwikTracker,
        UsePiwikTracker,
        DateRangeService,
        ScreenGeneral,
        ScrollingService,
        ContainersTracingService,
        AccessibilityService,
        MySettingsShareDataService,
        FileService,
        {provide: APP_INITIALIZER, useFactory: environmentInit, deps: [EnvironmentConfiguration], multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: ErrorHandlerInterceptor, multi: true}
    ]
})
export class CoreModule {
}
