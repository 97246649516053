import { Routes } from '@angular/router';
import { UmbracoConfigGuard } from 'app/auth/umbraco-config-guard';
import { MaintanceErrorComponent } from 'app/containers/error-page/maintance-error/maintance-error';
import { MaintenanceGuard } from './auth/maintenance.guard';
const ɵ0 = () => import("./containers/layout/layout.module.ngfactory").then(m => m.LayoutModuleNgFactory);
const appRoutes = [
    { path: '', redirectTo: 'app', pathMatch: 'full' },
    { path: 'app', loadChildren: ɵ0, canActivate: [UmbracoConfigGuard] },
    { path: 'maintance-error', component: MaintanceErrorComponent, canActivate: [MaintenanceGuard] },
    { path: '**', redirectTo: 'app' }
];
export class AppRoutingModule {
}
export { ɵ0 };
