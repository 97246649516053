import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import * as __NgCli_bootstrap_1 from "./app/app.module.ngfactory";
import * as __NgCli_bootstrap_2 from "@angular/platform-browser";
const envConf = window['caEnvironmentConfig'];
if (environment.production) {
    enableProdMode();
}
// Handle redirection errors from AzureAD
if (window.location.href.includes('error_description=AADB2C90118')) {
    window.location.href = envConf.signIn.forgotPasswordLink;
}
else if (window.location.href.includes('error_description=AADB2C90091') ||
    (!window.location.href.includes('state=') && window.location.href.includes('id_token='))) {
    window.location.href = window.location.origin;
}
else {
    __NgCli_bootstrap_2.platformBrowser().bootstrapModuleFactory(__NgCli_bootstrap_1.AppModuleNgFactory);
}
// platformBrowserDynamic().bootstrapModule(AppModule);
// export function main(): Promise<any> {
//   return platformBrowserDynamic()
//     .bootstrapModule(AppModule)
//     .then(decorateModuleRef)
//     .catch(err => console.error(err));
// }
// // needed for hmr
// // in prod this is replace for document ready
// bootloader(main);
