import {Injectable} from '@angular/core';
import {EnvironmentConfiguration} from 'app/config/evironment-config';
import {RouterWrapper} from 'app/core/routing/router.wrapper';
import {RequestHelper} from 'app/core/http/request-helper';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class ConfigService {
    private baseApi: any = {};
    private appConfig: any = {};

    constructor(private http: HttpClient,
                private environmentConfig: EnvironmentConfiguration,
                private requestHelper: RequestHelper,
                private routeWrapper: RouterWrapper) {
        this.baseApi = this.environmentConfig.get('baseApiUrl');
    }

    loadConfig(): Promise<any> {
        return new Promise((resolve: any) => {
            const headers = this.requestHelper.initHeaders(null);
            const umbraco = this.environmentConfig.get('umbraco');
            const url = this.environmentConfig.get('getSettingsBypassingOSB') ? umbraco.settingsBypassingOSB : umbraco.settings;
            this.http.get(this.baseApi + url, {headers: headers})
                .catch(error => this.requestHelper.errorHandler(error)).subscribe(config => {
                this.appConfig = config;
                if (this.appConfig['systemUndermaintenance']) {
                    this.routeWrapper.toErrorPage({translationKey: 'Errors.General.SystemUnderMaintenance'}, true);
                    resolve(false);
                    return;
                }
                resolve(true);
            }, error => {
                    this.routeWrapper.toErrorPage();
                    resolve(false);
            });
        });
    }

    get(key: any) {
        if (this.appConfig === undefined) {
            return null;
        }

        return this.appConfig[key];
    }
}
