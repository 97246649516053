import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {MsalService} from '@azure/msal-angular';

@Injectable()
export class MaintenanceGuard implements CanActivate {

    constructor(private msalService: MsalService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
        return !!this.msalService.getAccount();
    }
}
