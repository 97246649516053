import {Injectable} from '@angular/core';
import {BehaviorSubject, of} from 'rxjs';

@Injectable()
export class SharedDataService {
    private sharedData: any = {};
    private headerTitle = new BehaviorSubject('');

    constructor() {
    }

    getMyShipData() {
        return this.sharedData['shipData'];
    }

    setMyShipData(data) {
        this.sharedData['shipData'] = data;
    }

    getDeliveryData() {
        return this.sharedData['deliveryData'];
    }

    setDeliveryData(data) {
        this.sharedData['deliveryData'] = data;
    }

    getZimMonitorDashData() {
        return this.sharedData['zimMonitorDashData'];
    }

    setZimMonitorDashData(data) {
        this.sharedData['zimMonitorDashData'] = data;
    }

    getZimMonitorBoxFilter() {
        return this.sharedData['zimMonitorBoxFilter'];
    }

    setZimMonitorBoxFilter(data) {
        this.sharedData['zimMonitorBoxFilter'] = data;
    }

    setDeliveryOrderTempBLNo(data) {
        this.sharedData['deliveryOrderTempBLNo'] = data;
    }

    getDeliveryOrderTempBLNo() {
        return this.sharedData['deliveryOrderTempBLNo'];
    }

    setBookingNumber(data) {
        this.sharedData['bookingTempBLNo'] = data;
    }

    getBookingNumber() {
        return this.sharedData['bookingTempBLNo'];
    }

    setHeaderTitle(data) {
        this.headerTitle.next(data);
    }

    getHeaderTitle() {
        return this.headerTitle.asObservable();
    }
}
