import * as i0 from "@angular/core";
export class MobileService {
    isMobile() {
        return window.innerWidth < 768;
    }
    isTablet() {
        return window.innerWidth >= 768 && window.innerWidth < 992;
    }
    isDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
    isApple() {
        return (navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
            navigator.userAgent &&
            navigator.userAgent.indexOf('CriOS') === -1 &&
            navigator.userAgent.indexOf('FxiOS') === -1);
    }
    isIE() {
        return window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
    }
}
MobileService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function MobileService_Factory() { return new MobileService(); }, token: MobileService, providedIn: "root" });
