import { Injectable } from '@angular/core';
import { HttpClientService } from 'app/core/http/http-client.service';
import { RouterWrapper } from 'app/core/routing/router.wrapper';
import { CookieService } from 'ngx-cookie';
import { environment } from '../../environments/environment';
import { Subject, Observable, BehaviorSubject } from 'rxjs';
import { JwtHelper } from 'app/shared/helpers/JWTParser';
import { EnvironmentConfiguration } from 'app/config/evironment-config';
import { MsalService, BroadcastService } from '@azure/msal-angular';
import { MobileService } from '../shared/services/mobile.service';
import {Logger} from 'msal';
import {CryptoUtils} from 'msal/lib-es6';

@Injectable()
export class AuthService {
    baseApi: any;
    jwtHelper: JwtHelper;
    private authState: Subject<any> = new Subject();
    envType = 'zim';

    constructor(private httpClient: HttpClientService,
        private environmentConfig: EnvironmentConfiguration,
        private broadcastService: BroadcastService,
        private routerWrapper: RouterWrapper,
        private cookieService: CookieService,
        private msalService: MsalService,
        private mobileService: MobileService) {
        this.jwtHelper = new JwtHelper();
        this.baseApi = environmentConfig.get('baseApiUrl');
        this.envType = this.environmentConfig.get('type').toLowerCase();

        this.msalService.handleRedirectCallback((authError, response) => {

            if (this.mobileService.isApple() || this.mobileService.isIE()) {
                this.setTokenFromMsalData();
            }
            const envConf = window['caEnvironmentConfig'];
            const msalErrorDescription = localStorage.getItem(
                'msal.error.description'
            );
            if (msalErrorDescription) {
                if (msalErrorDescription.indexOf('AADB2C90118') > -1) {
                    window.location.href = envConf.signIn.forgotPasswordLink;
                    return;
                }
            }
        });

        this.broadcastService.subscribe('msal:acquireTokenSuccess', payload => {
            if (payload.accessToken) {
                this.cookieService.put('zim_access_token', payload.accessToken);
            }
        })
    }

    authInit() {
        if (this.environmentConfig.get('enableMSALLogger')) {
            this.msalService.setLogger(new Logger((logLevel, message, piiEnabled) => {
                console.log('MSAL Logging: ', message);
            }, {
                correlationId: CryptoUtils.createNewGuid(),
                piiLoggingEnabled: false
            }));
        }
        if (!!this.msalService.getAccount()) {
            this.checkErrors();

            this.getAuthData().subscribe(data => {
                if (data.contact == null || data.dsaData == null) {
                    this.routerWrapper.toLogin();
                    return;
                }
                this.cookieService.put('language', data.contact.language);
                this.setAuthData(data);
                if (this.envType !== 'zim') {
                    this.routerWrapper.toPrint();
                }
            })
        } else {
            this.msalService.loginRedirect();
        }
    }

    getAuthState(): Subject<any> {
        return this.authState;
    }

    setAuthState(auth: boolean): void {
        this.authState.next(auth);
    }

    isAuthentificated(): boolean {
        return !!this.msalService.getAccount();
    }


    getAuthData() {
        return this.httpClient.get(this.baseApi + this.environmentConfig.get('getmycontactEndPoint').contacts);
    }

    setAuthData(data: any) {
        sessionStorage.setItem('zim_auth_data', JSON.stringify(data));

        this.setAuthState(true);
    }

    removeAuthData() {
        sessionStorage.removeItem('zim_auth_data');
        this.setAuthState(false);
    }

    checkTokenDsa(token: string): boolean {
        const parsedObj = this.jwtHelper.decodeToken(token);
        return parsedObj && parsedObj['extension_DSAContactId'] !== '';
    }

    logout(): void {
        this.clearAuthorizationData();
        this.routerWrapper.toLogout();
    }

    clearAuthorizationData(): void {
        this.cookieService.remove('zim_access_token');
        this.cookieService.remove('refresh_token');
        this.cookieService.remove('zim_customer_services');
        this.cookieService.remove('expires_in');
        this.removeAuthData();
    }

    checkErrors() {
        if (window.location.href.includes('error_description=AADB2C90118')) {
            window.location.href = this.environmentConfig.get('signIn').forgotPasswordLink;
            return;
        } else if (window.location.href.includes('error_description=AADB2C90091')) {
            this.msalService.loginRedirect();
            return;
        } else if (window.location.href.includes('error_description=AADB2C90075')) {
            this.routerWrapper.toErrorPage();
            return;
        }
    }

    setTokenFromMsalData() {
        for (const key of Object.keys(localStorage)) {
            try {
                const parsedKey = JSON.parse(key);
                if (typeof parsedKey === 'object' && parsedKey.authority && parsedKey.clientId && parsedKey.homeAccountIdentifier) {
                    const data = JSON.parse(localStorage.getItem(key));
                    this.cookieService.put('zim_access_token', data.accessToken);
                    return;
                }
            } catch (e) {
                console.log(e)
            }
        }
    }
}
