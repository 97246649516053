import {Injectable} from '@angular/core';
@Injectable({
    providedIn: 'root'
})
export class MobileService {

    isMobile(): boolean {
        return window.innerWidth < 768;
    }

    isTablet(): boolean {
        return window.innerWidth >= 768 && window.innerWidth < 992;
    }

    isDevice() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    isApple() {
        return (navigator.vendor && navigator.vendor.indexOf('Apple') > -1 &&
            navigator.userAgent &&
            navigator.userAgent.indexOf('CriOS') === -1 &&
            navigator.userAgent.indexOf('FxiOS') === -1);
    }

    isIE() {
        return window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
    }
}
