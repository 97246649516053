import {Injectable} from '@angular/core';

@Injectable()
export class EnvironmentConfiguration {
    private staticConfig: any = {};

    private readonly RouteApiEndpointMap = {
        'print': 'printEndPoint',
        'zim-monitor': 'monitorEndPoint',
        'imp-my-shipments': 'myShipmentsImportEndPoint',
        'exp-my-shipments': 'myShipmentsExportEndPoint',
        'arrival-notice': 'arrivalNoticeEndPoint',
        'delivery-order': 'deliveryOrderEndPoint',
        'booking-confirmation': 'bookingConfirmation',
        'bl-copy': 'blCopysEndPoint',
        'seaway-bill': 'seawayBillEndPoint',
        'finance': 'financeEndPoint',
        'dashboard': 'dashboardEndPoint',
        'user-management': 'userManagementEndPoint',
        'draft-bl': 'draftBLEndPoint',
        'vgm': 'vgmEndPoint'
    };

    constructor() {
    }

    loadConfig(): Promise<any> {
        const stcConf = window['caStaticEnvironmentConfig'];
        const envConf = window['caEnvironmentConfig'];

        return new Promise((resolve: any) => {
            this.staticConfig = [] ;
            for (const key in stcConf) {
                this.staticConfig[key] = stcConf[key];
            }
            for (const key in envConf) {
                this.staticConfig[key] = envConf[key];
            }
            resolve(true);
        });
    }

    get(key: any) {
        return this.staticConfig[key];
    }

    getByRoute(routeName: string) {
        return this.get(this.RouteApiEndpointMap[routeName]);
    }
}

