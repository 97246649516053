import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer,
    Renderer2,
    ViewChild
} from '@angular/core';
import { Router } from '@angular/router';

import { StorageDataReader } from '../../../shared/services/storage-data-reader.service';
import { NavbarService } from './navbar.service';
import { AuthService } from '../../../auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { CookieService } from 'ngx-cookie';
import { ConfigService } from '../../../config/config.sevice';
import { Global } from 'app/shared/global';
import { Subscription, interval } from 'rxjs';
import { MySettingsShareDataService } from '../../../shared/services/my-settings-share-data.service';
import { NotificationsService } from '../../notifications/notifications.service';
import { RoutePermissionService } from '../../../core/permission/route-permissions.service';
import { StylesheetSwitcherService } from '../../../shared/services/stylesheet-switcher.service';
import { MySettingsService } from '../../my-settings/my-settings.service';
import { MobileService } from '../../../shared/services/mobile.service';
import { SharedDataService } from '../../../shared/services/shared-data.service';
import { EnvironmentConfiguration } from '../../../config/evironment-config';

declare const $: any;

@Component({
    selector: 'navbar',
    templateUrl: './navbar.template.html',
    providers: [MySettingsService]
})
export class NavbarComponent implements OnInit, OnDestroy {
    @Input() hideLangSwitcher;
    @Output() changeSidebarPosition = new EventEmitter();
    @Output() changeSidebarDisplay = new EventEmitter();
    @Output() openSidebar = new EventEmitter();
    display: string = 'Left';
    initLanguage: string;
    searchFormState: boolean = true;
    settings: any = {
        isOpen: false
    };
    @ViewChild('listHandler', {static: false}) listHandler: ElementRef;
    @ViewChild('menuHandler', {static: false}) menuHandler: ElementRef;
    fullName: string = '';
    showPersonal: boolean = false;
    showLogout: boolean = false;
    showMySettingsLink: boolean = false;
    showLanguageSelect: boolean = false;
    subPages: any = {};
    enableTopMenu: boolean = false;
    zimAuthData: any;
    baseApi: string;
    apiEndPoint: string;
    showPersonalInfo = false;
    userEmail: string = '';
    mySettingsDataSubscription: Subscription;
    notificationPollerInterval: number;
    notificationPoller: any;
    showNotificationsExistence: boolean = false;
    isNotificationsShowed = false;
    languages: [{ id: string, text: string }];
    myProfileData: any;
    isMobile: any;
    headerTitle: string = '';
    envType = 'zim';

    constructor(private renderer: Renderer,
        private renderer2: Renderer2,
        private cookiesService: CookieService,
        public translate: TranslateService,
        private el: ElementRef,
        private router: Router,
        public authService: AuthService,
        public navbarService: NavbarService,
        private localStor: StorageDataReader,
        private global: Global,
        public mobileService: MobileService,
        private mySettingsShareDataService: MySettingsShareDataService,
        private configService: ConfigService,
        private mySettingsService: MySettingsService,
        private stylesheetSwitcherService: StylesheetSwitcherService,
        private notificationsService: NotificationsService,
        private permissionsService: RoutePermissionService,
        private sharedDataService: SharedDataService,
        private environmentConfig: EnvironmentConfiguration) {
        this.zimAuthData = this.localStor.getZimAuthData();
        this.enableTopMenu = configService.get('enableTopMenu');
        this.showMySettingsLink = configService.get('showMySettingsLink');
        this.isNotificationsShowed = permissionsService.getPermissions()['PUSHNTF'];
        this.setNotificationPoller();
        this.showLanguageSelect = configService.get('showLanguages');
        if (configService.get('languages')) {
            this.languages = configService.get('languages').map(lang => {
                return { id: lang.iso, text: lang.text }
            });
        } else {
            this.showLanguageSelect = false;
        }
        this.envType = this.environmentConfig.get('type').toLowerCase();
    }

    ngOnInit(): void {
        if (this.hideLangSwitcher) {
            this.showLanguageSelect = false;
        }
        this.getZimAuthData();
        this.checkExistingAuthData();
        this.getMyProfileData();
        this.sharedDataService.getHeaderTitle().subscribe(title => this.headerTitle = title);
        this.isMobile = this.mobileService.isMobile();

        const lang = this.cookiesService.get('language') || 'en';
        if (this.showLanguageSelect) {
            if (this.cookiesService.get('language')) {
                this.translate.use(lang);
                this.initLanguage = this.translate.currentLang;
            }

            this.translate.use(lang).subscribe(() => {
                this.initLanguage = this.translate.currentLang;
            });
        }


        this.authService.getAuthState().subscribe(value => {
            if (value) {
                this.generateFullName();
                this.showPersonal = true;
            } else {
                this.showPersonal = false;
            }
        });
        this.generateFullName();
        $('.user-block .btn-search, .search-form .close').click(function () {
            $('body').toggleClass('search-form-opened');
            return false;
        });
        $('.btn-menu').click(function () {
            if ($('body').hasClass('menu-opened') ||
                $('body').hasClass('contact-opened') ||
                $('body').hasClass('location-opened') ||
                $('body').hasClass('side-menu-opened')) {
                $('body').removeClass('menu-opened contact-opened location-opened side-menu-opened');
            } else {
                $('body').addClass('side-menu-opened');
            }
            return false;
        });

        if (this.configService.get('enableTopMenu')) {
            this.getSubPages();
        }

        this.mySettingsDataSubscription = this.mySettingsShareDataService.getData().subscribe(data => {
            if (data && data.firstName) {
                this.fullName = data.firstName.trim() + ' ' + data.lastName;
                this.myProfileData = data;
                this.initLanguage = data.preferredLang;
            }
        });

        this.notificationsService.getResetState().subscribe(state => {
            this.showNotificationsExistence = !state;
        })
    }

    ngOnDestroy() {
        this.mySettingsDataSubscription.unsubscribe();
    }

    languageChanged(lang: string) {
        this.cookiesService.put('language', lang);
        this.translate.use(lang);
        this.global.currentLanguage = lang;
        this.stylesheetSwitcherService.switchStylesheet(lang);
        if (this.initLanguage !== lang) {
            this.sendLanguage(lang);
        }
        this.initLanguage = lang;

        $('body').removeClass('location-opened');
    }

    getZimAuthData(): void {
        const retrieveData = sessionStorage.getItem('zim_auth_data');
        this.zimAuthData = JSON.parse(retrieveData);
    }

    generateFullName() {
        const data = this.zimAuthData;

        if (data && data.contact) {
            const adminString = /\(Admin\)/g;
            const lastName = data.contact.lastName.replace(adminString, '').trim();
            this.fullName = data.contact.firstName + ' ' + lastName;
            this.userEmail = data.contact.userName;
        }
    }

    checkExistingAuthData() {
        const token = this.cookiesService.get('zim_access_token');

        if (token) {
            this.showLogout = true;
        }

        if (this.zimAuthData) {
            this.showPersonal = true;
        }
    }

    onLogout() {
        this.authService.logout();
    }

    sidebarPosition(position): void {
        this.changeSidebarPosition.emit(position);
    }

    sidebarDisplay(position): void {
        this.changeSidebarDisplay.emit(position);
    }

    sidebarOpen(): void {
        this.openSidebar.emit();
    }

    searchFormOpen(): void {
        if (this.searchFormState) {
            this.changeStyleElement('#search-form', 'height', '40px');
            this.changeStyleElement('.notifications ', 'top', '86px');
        } else {
            this.changeStyleElement('#search-form', 'height', '0px');
            this.changeStyleElement('.notifications ', 'top', '46px');
        }
        this.searchFormState = !this.searchFormState;
    }

    getSubPages() {
        this.navbarService.getSubPagesData().subscribe((data) => {
            this.subPages = data;
            this.onOpenList();
        });
    }

    onOpenList() {
        setTimeout(() => {
            $('.mobile-nav .drop-menu').parent('li').addClass('has-drop');
            $('.mobile-nav .has-drop > a').append('<span class="opener"><span>Open Drop</span></span>');
            $('.mobile-nav .has-drop').click(function () {
                const parent_ = $(this).closest('li');
                if (parent_.hasClass('active')) {
                    parent_.removeClass('active').children('.drop-menu').slideUp(300);
                } else {
                    parent_.addClass('active').children('.drop-menu').slideDown(300);
                    parent_.siblings('.active').removeClass('active').children('.drop-menu').slideUp(300);
                }
                return false;
            });
        }, 300);
    }

    onDashboardNavigate() {
        this.router.navigateByUrl('app/dashboard');
    }

    onPrintBLNavigate() {
        this.router.navigateByUrl('app/print');
    }

    onMySettingsNavigate() {
        this.router.navigateByUrl('app/my-settings');
    }

    onNotificationSettingsNavigate() {
        this.router.navigateByUrl('app/notifications');
    }

    onShowPersonalInfoToggle() {
        this.showPersonalInfo = !this.showPersonalInfo;
    }

    private changeStyleElement(selector, styleName, styleValue): void {
        this.renderer.setElementStyle(this.el.nativeElement
            .querySelector(selector), styleName, styleValue);
    }

    setNotificationPoller() {
        this.notificationPollerInterval = this.configService.get('notificationsCheckInterval') || 5;
        if (this.notificationPollerInterval > 0 && this.isNotificationsShowed) {
            this.getNotificationsCounter();
            const parsedInterval = this.notificationPollerInterval * 60000;
            this.notificationPoller = interval(parsedInterval).map(() => {
                this.getNotificationsCounter();
            });
            this.notificationPoller.subscribe();
        }
    }

    getNotificationsCounter() {
        if (this.zimAuthData) {
            const dsaId = this.zimAuthData.dsaData.dsaId;

            this.notificationsService.getNewNotificationsCounter(dsaId).subscribe(response => {
                this.showNotificationsExistence = response.numberOfNewNotifications > 0;
            })
        }
    }

    sendLanguage(language: string) {
        const request = this.myProfileData;
        request.preferredLang = language;

        this.mySettingsShareDataService.setNavLanguage(language);
        this.mySettingsService.setMyUserProfile(request).subscribe();
    }

    getMyProfileData() {
        if (!this.zimAuthData) {
            return;
        }
        const request = {
            'dsaID': this.zimAuthData.dsaData.dsaId
        };

        this.mySettingsService.getMyUserProfile(request).subscribe(response => {
            this.myProfileData = {
                dsaID: this.zimAuthData.dsaData.dsaId,
                firstName: response.firstName,
                lastName: response.lastName,
                preferredLang: response.language,
                timeZone: response.timeZone,
                mobileNo: response.mobileNo,
                myAdminZim: response.myAdminZim,
                myAdminZimEmail: response.myAdminZimEmail
            };
            this.mySettingsShareDataService.sendData(this.myProfileData);
        });
    }

    closePersonalInfo() {
        this.showPersonalInfo = false;
    }
}
