import {Configuration} from 'msal';
import {MsalAngularConfiguration} from '@azure/msal-angular';
const envConf = window['caEnvironmentConfig'];

export const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export const apiConfig: {b2cScopes: string[], webApi: string} = {
    b2cScopes: [envConf.signIn.b2cScopes],
    webApi: envConf.baseApiUrl
};

export const msalConfig: Configuration = {
    auth: {
        clientId: envConf.signIn.clientId,
        authority: envConf.signIn.authorityLink,
        redirectUri: envConf.signIn.redirectUrl,
        navigateToLoginRequestUrl: false,
        validateAuthority: false,
    },
    cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE,
    },
    system: {
        loadFrameTimeout: 60000,
        tokenRenewalOffsetSeconds: 60000
    }
}

export const loginRequest: {scopes: string[]} = {
    scopes: ['openid', 'profile'],
};

export const tokenRequest: {scopes: string[]} = {
    scopes: apiConfig.b2cScopes
};

export const protectedResourceMap: [string, string[]][] = [
    [apiConfig.webApi, apiConfig.b2cScopes]
];

export const msalAngularConfig: MsalAngularConfiguration = {
    popUp: false,
    consentScopes: [
        ...loginRequest.scopes,
        ...tokenRequest.scopes,
    ],
    unprotectedResources: [],
    protectedResourceMap,
    extraQueryParameters: {}
}

export function MSALConfigFactory(): Configuration {
    return msalConfig;
}

export function MSALAngularConfigFactory(): MsalAngularConfiguration {
    return msalAngularConfig;
}
