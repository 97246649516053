import { Inject, Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {BroadcastService, MSAL_CONFIG, MSAL_CONFIG_ANGULAR, MsalAngularConfiguration, MsalGuard, MsalService} from '@azure/msal-angular';
import {Configuration} from 'msal';
import { Location, PlatformLocation } from '@angular/common';
import {MobileService} from '../shared/services/mobile.service';

@Injectable()
export class CustomMsalGuard extends MsalGuard {

    constructor(
        @Inject(MSAL_CONFIG) msalConfig: Configuration,
        @Inject(MSAL_CONFIG_ANGULAR) msalAngularConfig: MsalAngularConfiguration,
        router: Router,
        activatedRoute: ActivatedRoute,
        location: Location,
        platformLocation: PlatformLocation,
        broadcastService: BroadcastService,
        private msalService: MsalService,
        private mobileService: MobileService
    ) {
        super( msalConfig, msalAngularConfig, msalService, router, activatedRoute, location, platformLocation, broadcastService);
    }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
        if (this.mobileService.isApple() || this.mobileService.isIE()) {
            this.msalService.getLogger().verbose('location change event from old url to new url');
            if (!this.msalService.getAccount()) {
                this.msalService.loginRedirect();
                return false;
            }
            return true;
        } else {
            return super.canActivate(route, state);
        }
    }

}
